import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../api';

function PaymentStatus() {
  const [searchParams] = useSearchParams();
  const result = searchParams.get('result');
  const orderReference = searchParams.get('orderReference');
  const [selectedType, setSelectedType] = useState('OFFLINE');
  const [isGenerating, setIsGenerating] = useState(true);
  const intervalRef = useRef(null);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!intervalRef.current && orderReference && result === 'success') {
      intervalRef.current = setInterval(() => {
        api
          .get(`/orders/${orderReference}/tickets`, {
            responseType: 'blob',
          })
          .then((res) => {
            const { status } = res;
            if (status && status === 200) {
              setIsGenerating(false);
              clearInterval(intervalRef.current);
            }
          });
      }, 3000);
    }
  }, [orderReference, result]);

  /*const downloadTickets = () => {
    const tabWindow = window.open('', '_blank');
    api
      .get(`/orders/${orderReference}/tickets`, {
        responseType: 'blob',
      })
      .then((res) => {
        const { data } = res;
        openFileInNewTab(tabWindow, data, 'application/pdf');
      });
  };*/

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100vh', width: '100vw' }}
    >
      <a href="https://www.poftimcultura.ro">
        <img
          style={{ maxWidth: '400px', marginBottom: '40px', marginRight: '10px', marginLeft: '10px' }}
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="logo"
        />
      </a>
      {result === 'success' ? (
        <>
          <Typography gutterBottom align="center" variant="h5">
            Comandă a fost plasată cu succes!
          </Typography>
          <Typography gutterBottom align="center" variant="subtitle1">
            În câteva momente vei primi biletele pe adresa de email furnizată
          </Typography>
          {orderReference && (
            <>
              {user && (
                <TextField
                  sx={{ width: 221, marginTop: 4 }}
                  disabled={isGenerating}
                  value={selectedType}
                  onChange={(event) => setSelectedType(event.target.value)}
                  select
                  label="Tip bilet"
                >
                  <MenuItem value={'OFFLINE'}>Offline</MenuItem>
                  <MenuItem value={'ONLINE'}>Online</MenuItem>
                </TextField>
              )}
              <Button
                sx={{ marginTop: 4 }}
                disabled={isGenerating}
                size="large"
                width={221}
                variant="contained"
                href={`${process.env.REACT_APP_API_BASE_URL}/orders/${orderReference}/tickets?type=${
                  !user ? 'ONLINE' : selectedType
                }`}
              >
                {isGenerating && <CircularProgress size={20} sx={{ mr: 1, color: '#868686' }} />}
                {isGenerating ? 'Pregătim biletele' : 'Descarcă bilete'}
              </Button>
            </>
          )}
        </>
      ) : result === 'paymentExpired' ? (
        <>
          <Typography gutterBottom align="center" variant="h5">
            Sesiunea de plata a expirat
          </Typography>
          <Typography align="center" variant="subtitle1">
            Vă rugăm să reîncercaţi
          </Typography>
        </>
      ) : (
        <>
          <Typography gutterBottom align="center" variant="h5">
            A intervenit o problemă
          </Typography>
          <Typography align="center" variant="subtitle1">
            Vă rugăm să reîncercaţi
          </Typography>
        </>
      )}
    </Grid>
  );
}

export default PaymentStatus;
