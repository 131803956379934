import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVenues } from '../redux/slices/venuesSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import VenuesDrawer from '../components/VenuesDrawer';

function Venues() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCreate, setOpenCreate] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const venuesData = useSelector((state) => state.venues.data);
  const error = useSelector((state) => state.venues.error);

  const onDrawerClose = () => {
    setOpenCreate(false);
  };

  const toggleOpenCreate = () => {
    setOpenCreate((openCreate) => !openCreate);
  };

  useEffect(() => {
    dispatch(fetchVenues());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);

  return (
    <div className="venues">
      <Grid container spacing={4}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3">Venues</Typography>
          </Grid>
          {user.role === 'admin' && (
            <Grid item>
              <Button variant="contained" onClick={toggleOpenCreate}>
                Add venue
              </Button>
            </Grid>
          )}
        </Grid>
        {venuesData.map(({ id, name, city, portrait_image_url }) => (
          <Grid key={id} item>
            <Card sx={{ width: '330px' }}>
              <CardHeader
                sx={{
                  '& .MuiCardHeader-content': {
                    overflow: 'hidden',
                  },
                }}
                titleTypographyProps={{ noWrap: true }}
                title={name}
                subheader={city}
              />
              <Divider />
              <CardActionArea onClick={() => navigate(`${id}`)}>
                {!portrait_image_url ? (
                  <Skeleton variant="rectangular" height="420px" />
                ) : (
                  <CardMedia component="img" image={portrait_image_url} />
                )}
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      {user.role === 'admin' && <VenuesDrawer open={openCreate} onClose={onDrawerClose} />}
    </div>
  );
}

export default Venues;
