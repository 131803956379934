import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedEvent, fetchEventLight, fetchEvents } from '../redux/slices/eventsSlice';
import { toggleShowStats } from '../redux/slices/uiSlice';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from '../components/TabPanel';
import EventsDrawer, { EventsDrawerType } from '../components/EventsDrawer';
import EventsTable from '../components/EventsTable';
import SaleReportsDialog from '../components/SaleReportsDialog';

function Events() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openCreate, setOpenCreate] = useState(false);
  const [openSaleReports, setSaleReportsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const eventsData = useSelector(({ events: { allEvents } }) => allEvents);
  const isLoading = useSelector((state) => state.events.isLoading);
  const eventsDataError = useSelector((state) => state.events.error);

  const showStats = useSelector((state) => state.ui.events.showStats);
  const toggleStats = () => dispatch(toggleShowStats());

  const notArchived = useMemo(
    () => eventsData.filter(({ status }) => status === 'NOT_SELLING' || status === 'SELLING'),
    [eventsData]
  );

  const archived = useMemo(() => eventsData.filter(({ status }) => status === 'ARCHIVED').reverse(), [eventsData]);

  const user = useSelector((state) => state.auth.user);

  const handleDrawerClose = () => {
    setOpenCreate(false);
    setSelectedEvent(null);
    dispatch(clearSelectedEvent());
  };
  const toggleOpenSaleReports = () => setSaleReportsOpen(!openSaleReports);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCopy = (eventId) => {
    dispatch(fetchEventLight({ eventId, fetchRawCategoryPricings: true }))
      .unwrap()
      .then((res) => {
        const { data: event } = res;
        setSelectedEvent(event);
        return event;
      })
      .catch((err) => {
        console.log(err);
        toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
      });
  };

  useEffect(() => {
    const selectedTabId = searchParams.get('selectedTabId');
    if (selectedTabId) {
      setTabIndex(+selectedTabId);
    } else {
      searchParams.set('selectedTabId', 0);
      setSearchParams(searchParams);
    }
  }, [setTabIndex, setSearchParams, searchParams]);

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  useEffect(() => {
    if (eventsDataError) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [eventsDataError]);

  return (
    <div className="events">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3">Events</Typography>
        </Grid>
        <Grid item>
          {user.role === 'organizer' && (
            <Button variant="contained" onClick={() => setOpenCreate(true)}>
              Add event
            </Button>
          )}
          {(user.role === 'organizer' || user.role === 'cashier') && (
            <>
              <IconButton sx={{ marginLeft: 1 }} onClick={handleMenu}>
                <MoreHorizIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    toggleOpenSaleReports();
                    handleClose();
                  }}
                >
                  Sale Reports
                </MenuItem>
              </Menu>
            </>
          )}
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={(_, newValue) => {
            searchParams.set('selectedTabId', newValue);
            setSearchParams(searchParams);
            setTabIndex(newValue);
          }}
        >
          <Tab label="Ongoing" />
          <Tab label="Archived" />
        </Tabs>
      </Box>
      <TabPanel disabled={isLoading} value={tabIndex} index={0}>
        {user.role === 'cashier' && (
          <FormGroup sx={{ display: 'flex', alignItems: 'end', marginBottom: 2 }}>
            <FormControlLabel control={<Switch checked={showStats} onChange={toggleStats} />} label="Show stats" />
          </FormGroup>
        )}
        <EventsTable onCopy={onCopy} events={notArchived} {...(user.role === 'cashier' ? { showStats } : {})} />
      </TabPanel>
      <TabPanel disabled={isLoading} value={tabIndex} index={1}>
        <EventsTable onCopy={onCopy} events={archived} />
      </TabPanel>
      {user.role === 'organizer' && (
        <EventsDrawer
          type={openCreate ? EventsDrawerType.CREATE : EventsDrawerType.COPY}
          open={openCreate || !!selectedEvent}
          event={selectedEvent}
          onClose={handleDrawerClose}
        />
      )}
      <SaleReportsDialog open={openSaleReports} onClose={toggleOpenSaleReports} />
    </div>
  );
}

export default Events;
