import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  holdToken: null,
  seats: [],
  openCheckout: false,
  totalPrice: 0,
};

export const PromotionType = {
  ONE_PLUS_ONE: 'ONE_PLUS_ONE',
  THREE_PLUS_ONE: 'THREE_PLUS_ONE',
  NONE: '',
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    addSeat: (state, action) => {
      let seat = { isFree: false, ...action.payload };

      state.seats.push(seat);
      state.totalPrice += seat.price;
    },

    addSeatNPlusOne: (state, action) => {
      let seat = { isFree: false, ...action.payload.seat };
      const { promotionType } = action.payload;

      const divider = promotionType === PromotionType.ONE_PLUS_ONE ? 2 : 4;

      const sameCategoryCount =
        state.seats.filter(({ categoryId, typeId }) => categoryId === seat.categoryId && typeId === seat.typeId)
          .length + 1;
      if (sameCategoryCount % divider === 0) {
        seat.isFree = true;
      }

      state.seats.push(seat);

      if (!seat.isFree) {
        state.totalPrice += seat.price;
      }
    },

    removeSeat: (state, action) => {
      const idToRemove = action.payload;
      const idx = state.seats.findIndex(({ id }) => idToRemove === id);
      if (idx !== -1) {
        state.totalPrice -= state.seats[idx].price;
        state.seats.splice(idx, 1);
      }
    },

    removeSeatNPlusOne: (state, action) => {
      const { promotionType } = action.payload;
      const idToRemove = action.payload.id;
      const idx = state.seats.findIndex(({ id }) => idToRemove === id);

      if (idx !== -1) {
        state.seats.splice(idx, 1);
        state.totalPrice = 0;

        const objCats = state.seats
          .map(({ categoryId, typeId }) => `${categoryId}-${typeId}`)
          .reduce((acc, value) => ({ ...acc, [value]: 0 }), {});

        const divider = promotionType === PromotionType.ONE_PLUS_ONE ? 2 : 4;

        for (let i = 0; i < state.seats.length; i++) {
          const catAndTypeId = `${state.seats[i].categoryId}-${state.seats[i].typeId}`;
          objCats[catAndTypeId]++;
          if (objCats[catAndTypeId] % divider === 0) {
            state.seats[i].isFree = true;
          } else {
            state.seats[i].isFree = false;
            state.totalPrice += state.seats[i].price;
          }
        }
      }
    },

    clearSeats: (state) => {
      state.seats = [];
      state.totalPrice = 0;
      state.openCheckout = false;
    },

    setHoldToken: (state, action) => {
      state.holdToken = action.payload;
    },

    clearHoldToken: (state, action) => {
      state.holdToken = null;
    },

    setOpenCheckout: (state, action) => {
      state.openCheckout = action.payload;
    },
  },
});

export const {
  addSeat,
  addSeatNPlusOne,
  removeSeat,
  removeSeatNPlusOne,
  clearSeats,
  setEventId,
  clearEventId,
  setHoldToken,
  clearHoldToken,
  setOpenCheckout,
} = cartSlice.actions;

export default cartSlice.reducer;
