import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import api from '../../api';

export const createOrder = createAsyncThunk('orders/create', (args, { rejectWithValue }) => {
  const { event_id, user, object_ids, hold_token, payment_type, receipt_nr } = args;
  return api
    .post(`/events/${event_id}/orders`, { user, object_ids, hold_token, payment_type, receipt_nr })
    .then((response) => ({ status: response.status, data: response.data }))
    .catch((err) => rejectWithValue({ status: err.response.status, data: err.response.data }));
});

export const fetchOrders = createAsyncThunk('orders/list', (args) => {
  const { search, from, to, play, city, status } = args;
  return api
    .get(
      `/orders?to=${encodeURIComponent(to.toISOString())}&from=${encodeURIComponent(
        from.toISOString()
      )}&play=${encodeURIComponent(play)}&city=${encodeURIComponent(city)}&status=${encodeURIComponent(
        status
      )}&search=${encodeURIComponent(search)}`
    )
    .then((response) => response.data);
});

export const updateOrder = createAsyncThunk('orders/update', (args, { dispatch, getState }) => {
  const { order_id, status } = args;
  const state = getState();
  const options = {
    ...state.orders.options,
    to: dayjs(state.orders.options.to),
    from: dayjs(state.orders.options.from),
  };
  return api.put(`/orders/${order_id}`, { status }).then(() => dispatch(fetchOrders(options)).unwrap());
});

const initialState = {
  data: [],
  error: null,
  isLoading: false,
  isUpdating: false,

  options: {
    search: '',
    from: dayjs().add(-1, 'weeks').startOf('day').toISOString(),
    to: dayjs().toISOString(),
    play: 0,
    city: 'All',
    status: 'ALL',
  },
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialState,
  reducers: {
    setFilters: (state, action) => {
      const { search, from, to, play, city, status } = action.payload;

      if (search !== undefined) state.options.search = search;
      if (from !== undefined) state.options.from = from;
      if (to !== undefined) state.options.to = to;
      if (play !== undefined) state.options.play = play;
      if (city !== undefined) state.options.city = city;
      if (status !== undefined) state.options.status = status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrder.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createOrder.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(fetchOrders.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });

    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateOrder.pending, (state, action) => {
      state.isUpdating = true;
      state.error = null;
    });

    builder.addCase(updateOrder.fulfilled, (state, action) => {
      state.isUpdating = false;
    });

    builder.addCase(updateOrder.rejected, (state, action) => {
      state.isUpdating = false;
      state.error = action.error.message;
    });
  },
});

export const { setFilters } = ordersSlice.actions;
export default ordersSlice.reducer;
