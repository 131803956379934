import dayjs from 'dayjs';
import validator from 'validator';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { createOrder } from '../redux/slices/ordersSlice';
import seatInfoFormatter from '../utils/formatters';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

dayjs.locale('ro');

const CheckoutStep = {
  FIRST: 1,
  SECOND: 2,
};

const commonConfig = {
  firstName: {
    value: '',
    error: false,
    errorText: 'Câmp necompletat',
  },
  lastName: {
    value: '',
    error: false,
    errorText: 'Câmp necompletat',
  },
  email: {
    value: '',
    error: false,
    errorText: 'Email invalid',
  },
  phone: {
    value: '',
    error: false,
    errorText: 'Număr telefon invalid',
  },

  agreeToTerms: {
    value: false,
    error: false,
    errorText: 'Câmp nebifat',
  },

  paymentType: {
    value: '',
    error: false,
    errorText: 'Mod plata neselectat',
  },

  receiptNr: {
    value: '',
    error: false,
    errorText: 'Câmp necompletat',
  },
};

const formConfig = {
  firstName: {
    ...commonConfig.firstName,
    isValid: (value) => {
      return !validator.isEmpty(value, { ignore_whitespace: true });
    },
  },
  lastName: {
    ...commonConfig.lastName,
    isValid: (value) => {
      return !validator.isEmpty(value, { ignore_whitespace: true });
    },
  },
  email: {
    ...commonConfig.email,
    isValid: (value) => {
      return validator.isEmail(value);
    },
  },
  phone: {
    ...commonConfig.phone,
    isValid: (value) => {
      return validator.isMobilePhone(value);
    },
  },

  agreeToTerms: {
    ...commonConfig.agreeToTerms,
    isValid: (value) => {
      return value;
    },
  },

  paymentType: {
    ...commonConfig.paymentType,
    isValid: (value) => {
      return ['CASH', 'CARD'].includes(value);
    },
  },

  receiptNr: {
    ...commonConfig.receiptNr,
    isValid: (value) => {
      return !validator.isEmpty(value, { ignore_whitespace: true });
    },
  },
};

const optionalFormConfig = {
  firstName: {
    ...commonConfig.firstName,
    isValid: (value) => {
      return true;
    },
  },

  lastName: {
    ...commonConfig.lastName,
    isValid: (value) => {
      return true;
    },
  },

  email: {
    ...commonConfig.email,
    isValid: (value) => {
      return validator.isEmpty(value, { ignore_whitespace: true }) || validator.isEmail(value);
    },
  },

  phone: {
    ...commonConfig.phone,
    isValid: (value) => {
      return validator.isEmpty(value, { ignore_whitespace: true }) || validator.isMobilePhone(value);
    },
  },

  /*agreeToTerms: {
    ...commonConfig.agreeToTerms,
    isValid: (value) => {
      return value;
    },
  },*/

  paymentType: {
    ...commonConfig.paymentType,
    isValid: (value) => {
      return ['CASH', 'CARD'].includes(value);
    },
  },

  receiptNr: {
    ...commonConfig.receiptNr,
    isValid: (value) => {
      return true;
    },
  },
};

const FirstStepContent = ({ user, event, seats, formState, onInputChange }) => {
  const theme = useTheme();
  const reqUserData = useMemo(() => !user, [user]);
  return (
    <>
      <Typography component="h2" variant="h6">
        Detalii client
      </Typography>
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <TextField
            name="firstName"
            value={formState.firstName.value}
            onChange={onInputChange}
            error={formState.firstName.error}
            helperText={formState.firstName.error ? formState.firstName.errorText : ''}
            required={reqUserData}
            fullWidth
            variant="standard"
            type="text"
            label="Prenume"
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="lastName"
            value={formState.lastName.value}
            onChange={onInputChange}
            error={formState.lastName.error}
            helperText={formState.lastName.error ? formState.lastName.errorText : ''}
            required={reqUserData}
            fullWidth
            variant="standard"
            type="text"
            label="Nume"
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="email"
            value={formState.email.value}
            onChange={onInputChange}
            error={formState.email.error}
            helperText={formState.email.error ? formState.email.errorText : ''}
            fullWidth
            variant="standard"
            required={reqUserData}
            type="email"
            label="Email"
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={formState.phone.value}
            onChange={onInputChange}
            error={formState.phone.error}
            helperText={formState.phone.error ? formState.phone.errorText : ''}
            name="phone"
            required={reqUserData}
            fullWidth
            variant="standard"
            type="tel"
            label="Număr telefon"
          ></TextField>
        </Grid>
      </Grid>
      <Typography sx={{ mt: 2 }} gutterBottom variant="h6">
        Detalii comandă
      </Typography>
      <Typography variant="subtitle1">
        {event.title} - {dayjs(event.date).format('ddd DD MMMM YYYY HH:mm')}
      </Typography>
      <Typography gutterBottom variant="subtitle1">
        {event.location}
      </Typography>
      <Typography gutterBottom variant="subtitle1">
        {event.city}
      </Typography>
      <Divider />
      {seats.length ? (
        <List>
          {seats.map(
            ({
              id,
              typeLabel,
              isDefaultType,
              section,
              row,
              seat,
              basePrice,
              discount,
              price,
              isFree,
              categoryLabel,
            }) => (
              <ListItem disablePadding key={id}>
                <ListItemText
                  primary={
                    <>
                      {categoryLabel}
                      {!isDefaultType && <Chip sx={{ marginLeft: 1 }} size="small" label={typeLabel} />}
                    </>
                  }
                  secondary={seatInfoFormatter(section, row, seat)}
                />
                <ListItemSecondaryAction sx={{ right: 0 }}>
                  {isFree || discount ? (
                    <Stack>
                      <span style={{ textDecoration: 'line-through', fontSize: '0.70rem' }}>
                        {(isFree ? price : basePrice).toFixed(2) + ' Lei'}
                      </span>
                      <span style={{ color: theme.palette.error.main }}>
                        {(isFree ? 0 : price).toFixed(2) + ' Lei'}
                      </span>
                    </Stack>
                  ) : (
                    price.toFixed(2) + ' Lei'
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            )
          )}
        </List>
      ) : (
        <Box sx={{ height: '100px' }} display="flex" alignItems="center" justifyContent="center">
          <Typography variant="subtitle1">Niciun loc selectat</Typography>
        </Box>
      )}
      <Divider />
      {!user && (
        <Box sx={{ mt: 2, mb: 2 }}>
          {
            <a href="https://www.librapay.ro" title="Plati online" target="_blank" rel="noreferrer">
              <img
                width="100%"
                src="https://www.librapay.ro/images/icon_securitate_LibraPay_600x60px.jpg"
                alt="Icon securitate LibraPay"
                border="0"
              />
            </a>
          }
        </Box>
      )}
    </>
  );
};

const FirstStepFooter = ({ user, isLoading, totalPrice, seats, formState, onInputChange, onAction, actionText }) => {
  return (
    <>
      {!user && (
        <Grid item>
          <FormControl error={formState.agreeToTerms.error}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="agreeToTerms"
                    required
                    checked={formState.agreeToTerms.value}
                    onChange={onInputChange}
                  />
                }
                label={
                  <p>
                    {'Am citit și sunt de-acord cu '}
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('https://poftimcultura.ro/termeni-si-conditii/');
                      }}
                    >
                      termenii și condițiile de utilizare
                    </Link>
                  </p>
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>
      )}
      <Grid sx={{ mt: 2 }} item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography component="h2" variant="h6">
            Total: {totalPrice.toFixed(2) + ' Lei'}
          </Typography>
        </Grid>
        <Grid item>
          <Button disabled={isLoading || !seats.length} onClick={onAction} variant="contained">
            {actionText}
            {isLoading && <CircularProgress size={20} sx={{ ml: 1, color: 'white' }} />}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const SecondStepContent = ({ formState, onInputChange }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" width="100%">
      <DialogContentText gutterBottom sx={{ textAlign: 'center', fontSize: 'large' }}>
        După ce plata a fost efectuată apăsați "Trimite comandă"
      </DialogContentText>
      <DialogContentText gutterBottom sx={{ fontSize: 'large', fontWeight: 'bold' }}>
        Tip plata: {formState.paymentType.value}
      </DialogContentText>
      {formState.paymentType.value === 'CARD' && (
        <TextField
          name="receiptNr"
          //sx={{ marginBottom: 4 }}
          value={formState.receiptNr.value}
          onChange={onInputChange}
          error={formState.receiptNr.error}
          helperText={formState.receiptNr.error ? formState.receiptNr.errorText : ''}
          variant="standard"
          type="text"
          label="Nr. Bon"
        />
      )}
    </Box>
  );
};

const SecondStepFooter = ({ totalPrice, seats, isLoading, onBack, onAction }) => {
  return (
    <Grid item container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography component="h2" variant="h6">
          Total: {totalPrice.toFixed(2) + ' Lei'}
        </Typography>
      </Grid>
      <Grid item>
        <Button sx={{ marginRight: 1 }} disabled={isLoading} onClick={onBack} variant="contained">
          Înapoi
        </Button>
        <Button disabled={isLoading || !seats.length} onClick={onAction} variant="contained">
          Trimite comandă
          {isLoading && <CircularProgress size={20} sx={{ ml: 1, color: 'white' }} />}
        </Button>
      </Grid>
    </Grid>
  );
};

function CheckoutDialog({ open, onClose, event, seats, setPaymentFormData, setOrderError }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [step, setStep] = useState(CheckoutStep.FIRST);
  const isLoading = useSelector((state) => state.orders.isLoading);
  const holdToken = useSelector(({ cart: { holdToken } }) => holdToken);
  const objectIds = useMemo(
    () =>
      seats.map(({ id, typeId, typeLabel, isDefaultType, price }) => ({
        id,
        typeId,
        typeLabel,
        isDefaultType,
        expectedPrice: price,
      })),
    [seats]
  );
  const totalPrice = useSelector(({ cart: { totalPrice } }) => totalPrice);

  const config = useMemo(() => (user ? optionalFormConfig : formConfig), [user]);

  const [formState, setFormState] = useState(config);

  const dispatchCreateOrder = (payload) => {
    dispatch(createOrder(payload))
      .unwrap()
      .then((res) => {
        const { paymentForm, orderReference } = res.data;
        // TODO: find a better way for this
        if (!paymentForm) navigate(`/orderStatus?result=success&orderReference=${orderReference}`);
        else setPaymentFormData(paymentForm);
        onCloseInternal();
      })
      .catch((err) => {
        const { errorType } = err.data;
        if (
          errorType &&
          (errorType === 'EVENT_STARTED_ERROR' ||
            errorType === 'EVENT_NOT_AVAILABLE_ERROR' ||
            errorType === 'PRICE_MISMATCH_ERROR')
        ) {
          setOrderError(errorType);
          onCloseInternal();
        } else if (errorType && errorType === 'INVALID_EMAIL_DOMAIN_ERROR') {
          const newState = Object.assign({}, formState);
          newState['email'].error = true;
          setFormState(newState);
        } else {
          toast.error('A apărut o eroare, vă rugăm să reîncărcați pagina și să încercați din nou!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  };

  const onInputChange = (input) => {
    setFormState((currentState) => ({
      ...currentState,
      [input.target.name]: {
        ...currentState[input.target.name],
        value: input.target.type === 'checkbox' ? input.target.checked : input.target.value,
        error: false,
      },
    }));
  };

  const onBackCashier = () => {
    setStep(CheckoutStep.FIRST);
  };

  const onNextCashier = () => {
    const BLACKLIST = ['receiptNr'];

    const inputs = Object.keys(formState).filter((i) => i && !BLACKLIST.includes(i));
    const newState = Object.assign({}, formState);

    let hasError = false;
    for (const input of inputs) {
      if (input && !newState[input].isValid(newState[input].value)) {
        hasError = true;
        newState[input].error = true;
      }
    }

    setFormState(newState);

    if (!hasError) {
      setStep(CheckoutStep.SECOND);
    }
  };

  const onFinishCashier = () => {
    const newState = Object.assign({}, formState);

    let hasError = false;
    if (newState['paymentType'].value === 'CARD' && !newState['receiptNr'].isValid(newState['receiptNr'].value)) {
      hasError = true;
      newState['receiptNr'].error = true;
    }

    setFormState(newState);

    if (!hasError) {
      const payload = {
        payment_type: newState.paymentType.value,
        receipt_nr: newState.receiptNr.value,
        event_id: event.id,
        user: {
          email: newState.email.value,
          first_name: newState.firstName.value,
          last_name: newState.lastName.value,
          phone: newState.phone.value,
        },
        object_ids: objectIds,
        hold_token: holdToken,
      };
      dispatchCreateOrder(payload);
    }
  };

  const onFinish = () => {
    const BLACKLIST = ['paymentType', 'receiptNr'];
    const inputs = Object.keys(formState).filter((i) => i && !BLACKLIST.includes(i));
    const newState = Object.assign({}, formState);
    let hasError = false;
    for (const input of inputs) {
      if (!newState[input].isValid(newState[input].value)) {
        hasError = true;
        newState[input].error = true;
      }
    }

    setFormState(newState);

    if (!hasError) {
      const payload = {
        event_id: event.id,
        user: {
          email: newState.email.value,
          first_name: newState.firstName.value,
          last_name: newState.lastName.value,
          phone: newState.phone.value,
        },
        object_ids: objectIds,
        hold_token: holdToken,
      };
      dispatchCreateOrder(payload);
    }
  };

  const onCloseInternal = () => {
    setFormState(formConfig);
    setStep(CheckoutStep.FIRST);
    onClose();
  };

  const userRole = useMemo(() => {
    if (user && user.role === 'organizer') {
      return ' (Organizator)';
    } else if (user && user.role === 'cashier') {
      return ' (Casier)';
    } else return '';
  }, [user]);

  const actionText = useMemo(() => {
    if (!user) return 'Plătește';
    else if (user.role === 'cashier') return 'Continuă';
    else if (user.role === 'organizer') return 'Trimite comandă';
  }, [user]);

  const onAction = () => {
    return user && user.role === 'cashier' ? onNextCashier() : onFinish();
  };

  return (
    <Dialog fullWidth maxWidth="sm" fullScreen={fullScreen} open={open}>
      <DialogTitle gutterBottom>
        Finalizare comandă
        {userRole}
        <IconButton
          aria-label="close"
          onClick={onCloseInternal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 16,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {step === CheckoutStep.FIRST ? (
          <FirstStepContent {...{ user, event, seats, formState, onInputChange }} />
        ) : (
          <SecondStepContent {...{ formState, onInputChange }} />
        )}
      </DialogContent>
      <DialogActions sx={{ paddingTop: 0, paddingLeft: 2.5, paddingRight: 2.5, paddingBottom: 2.5 }}>
        {step === CheckoutStep.FIRST ? (
          <Grid container direction="column">
            {user && user.role === 'cashier' && (
              <Grid item>
                <TextField
                  name="paymentType"
                  required
                  value={formState.paymentType.value}
                  onChange={onInputChange}
                  fullWidth
                  select
                  variant="standard"
                  label="Mod plata"
                  error={formState.paymentType.error}
                  helperText={formState.paymentType.error ? formState.paymentType.errorText : ''}
                >
                  <MenuItem value={'CASH'}>CASH</MenuItem>
                  <MenuItem value={'CARD'}>CARD</MenuItem>
                </TextField>
              </Grid>
            )}
            <FirstStepFooter
              {...{ user, isLoading, totalPrice, seats, formState, onInputChange, onAction, actionText }}
            />
          </Grid>
        ) : (
          <SecondStepFooter {...{ isLoading, totalPrice, seats, onBack: onBackCashier, onAction: onFinishCashier }} />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default CheckoutDialog;
