import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import NoData from './NoData';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
dayjs.extend(isBetween);

ChartJS.register(CategoryScale, LinearScale, TimeScale, BarElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      border: {
        display: false,
      },
      ticks: {
        color: 'white',
        beginAtZero: true,
        callback: function (value) {
          if (value % 1 === 0) {
            return value;
          }
        },
      },
      grid: { display: false },
    },
    x: {
      type: 'time',
      border: {
        display: false,
      },
      ticks: {
        color: 'white',
      },
      grid: {
        display: false,
      },
      time: {
        unit: 'day',
        displayFormats: {
          day: 'DD/MM',
        },
        tooltipFormat: 'DD/MM/YYYY',
      },
    },
  },
  plugins: {
    legend: {
      onClick: null,
      position: 'top',
      labels: { color: 'white' },
    },
  },
};

export default function SalesChart({ event }) {
  const { date, orders } = event;
  const user = useSelector((state) => state.auth.user);
  const chartDataCashier = useMemo(() => {
    let dates = [];
    if (orders.length) {
      const end = dayjs(date);
      const start = dayjs(orders[orders.length - 1].created_at);
      let counter = dayjs(start);

      while (counter.isBetween(start, end, 'day', '[]')) {
        const orderCount = orders.filter(
          // eslint-disable-next-line no-loop-func
          ({ created_at, is_organizer }) => !is_organizer && dayjs(created_at).isSame(counter, 'day')
        ).length;
        dates.push({ x: counter.format('YYYY-MM-DD'), y: orderCount });
        counter = counter.add(1, 'day');
      }
    }
    return {
      datasets: [
        {
          label: 'Sales',
          data: dates,
          maxBarThickness: 35,
          backgroundColor: 'rgba(102, 187, 106, 0.7)',
        },
      ],
    };
  }, [date, orders]);

  const chartDataOrganizer = useMemo(() => {
    let offlineDates = [];
    let onlineDates = [];
    if (orders.length) {
      const end = dayjs(date);
      const start = dayjs(orders[orders.length - 1].created_at);
      let counter = dayjs(start);

      while (counter.isBetween(start, end, 'day', '[]')) {
        const onlineCount = orders.filter(
          // eslint-disable-next-line no-loop-func
          ({ created_at, is_organizer, is_cashier }) =>
            !is_organizer && !is_cashier && dayjs(created_at).isSame(counter, 'day')
        ).length;

        const offlineCount = orders.filter(
          // eslint-disable-next-line no-loop-func
          ({ created_at, is_cashier }) => is_cashier && dayjs(created_at).isSame(counter, 'day')
        ).length;

        onlineDates.push({ x: counter.format('YYYY-MM-DD'), y: onlineCount });
        offlineDates.push({ x: counter.format('YYYY-MM-DD'), y: offlineCount });
        counter = counter.add(1, 'day');
      }
    }
    return {
      datasets: [
        {
          label: 'Online Sales',
          data: onlineDates,
          maxBarThickness: 35,
          backgroundColor: 'rgba(102, 187, 106, 0.7)',
        },
        {
          label: 'Offline Sales',
          data: offlineDates,
          maxBarThickness: 35,
          backgroundColor: 'rgba(10, 141, 219, 0.7)',
        },
      ],
    };
  }, [date, orders]);

  return (
    <>
      <Typography gutterBottom variant="h5">
        Sales chart
      </Typography>
      {orders.filter(({ is_organizer }) => !is_organizer).length ? (
        <Paper sx={{ position: 'relative', height: 400, width: '100%', p: 2, mb: 2, mt: 2 }}>
          <Bar options={options} data={user.role === 'organizer' ? chartDataOrganizer : chartDataCashier} />
        </Paper>
      ) : (
        <NoData />
      )}
    </>
  );
}
