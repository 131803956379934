import { useMemo } from 'react';
import { priceFormatter } from '../utils/formatters';

export default function ChartLegend({ categories }) {
  const flatCategories = useMemo(() => {
    const catPrices = categories
      .filter(({ available }) => available)
      .reduce(
        (acc, value) => ({
          ...acc,
          [value.categories_id]: [...(acc[value.categories_id] || []), value.price - value.price * value.discount],
        }),
        {}
      );
    return Object.keys(catPrices).map((key) => {
      const { name, color } = categories.find(({ categories_id }) => categories_id === +key);
      const prices = catPrices[key];
      const minPrice = Math.min(...prices);
      const maxPrice = Math.max(...prices);
      return {
        categoriesId: key,
        name,
        color,
        multiPrice: prices.length > 1 && minPrice !== maxPrice,
        minPrice,
        maxPrice,
      };
    });
  }, [categories]);

  return (
    <div
      style={{
        zIndex: 500,
        cursor: 'default',
        textAlign: 'center',
        maxHeight: '140px',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginTop: '10px',
        display: 'inline-flex',
        overflowY: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      {flatCategories.map(({ categoriesId, multiPrice, minPrice, maxPrice, name, color }) => (
        <div
          key={`${categoriesId}`}
          style={{
            marginLeft: '4px',
            marginRight: '4px',
            color: '#b3b3b3',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '10px',
            fontSize: '13px',
            fontWeight: '600',
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              marginRight: '6px',
              width: '12px',
              height: '12px',
              backgroundColor: color,
              borderRadius: '49%',
              flexShrink: 0,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'inline-flex',
              position: 'relative',
            }}
          />
          <span>{`${name}: ${
            multiPrice ? `${priceFormatter(minPrice)} - ${priceFormatter(maxPrice)}` : `${priceFormatter(minPrice)}`
          }`}</span>
        </div>
      ))}
    </div>
  );
}
