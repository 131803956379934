import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createNotifications, fetchNotifications } from '../redux/slices/notificationsSlice';
import { toast } from 'react-toastify';
import { seatIdToSectionParentOwn } from '../utils/formatters';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Typography from '@mui/material/Typography';
import WarningDialog from './WarningDialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';

const ChannelType = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
};

const NotificationStep = {
  FIRST: 1,
  SECOND: 2,
};

const FirstStep = ({
  includeAttachments,
  setIncludeAttachments,
  channel,
  setChannel,
  body,
  setBody,
  subject,
  setSubject,
}) => {
  const helperText = useMemo(() => {
    if (channel === ChannelType.SMS)
      return <Typography sx={{ textAlign: 'end' }} variant="body1">{`${body.length}/160`}</Typography>;
    return ' ';
  }, [channel, body]);

  const onChannelChange = useCallback(
    (event) => {
      setChannel(event.target.value);
      setSubject('');
      setBody('');
      setIncludeAttachments(false);
    },
    [setChannel, setBody, setSubject, setIncludeAttachments]
  );

  const onBodyChange = useCallback(
    (event) => {
      const newBody = event.target.value;
      if (channel !== ChannelType.SMS || (channel === ChannelType.SMS && newBody.length <= 160)) {
        setBody(newBody);
      }
    },
    [channel, setBody]
  );

  return (
    <>
      <Box mb={2}>
        <TextField value={channel} onChange={onChannelChange} fullWidth size="small" select label="Channel">
          <MenuItem value={ChannelType.EMAIL}>EMAIL</MenuItem>
          <MenuItem value={ChannelType.SMS}>SMS</MenuItem>
        </TextField>
      </Box>
      <TextField
        sx={{ mb: 2 }}
        disabled={channel === ChannelType.SMS}
        type="text"
        required
        fullWidth
        label="Subject"
        name="subject"
        size="small"
        value={subject}
        onChange={(event) => setSubject(event.target.value)}
      />
      <TextField
        multiline
        minRows={14}
        maxRows={14}
        required
        fullWidth
        name="body"
        label="Body"
        helperText={helperText}
        value={body}
        onChange={onBodyChange}
      />
      <FormGroup>
        <FormControlLabel
          disabled={channel === ChannelType.SMS}
          control={
            <Checkbox checked={includeAttachments} onChange={(event) => setIncludeAttachments(event.target.checked)} />
          }
          label="Include tickets as attachment"
        />
      </FormGroup>
    </>
  );
};

const SecondStep = ({ selected, setSelected, orders, channel, pricingTable }) => {
  const price = useMemo(() => pricingTable[channel] * selected.length, [channel, pricingTable, selected]);

  const validOrders = useMemo(
    () =>
      orders.filter(
        ({ email, phone }) => (channel === ChannelType.EMAIL && email) || (channel === ChannelType.SMS && phone)
      ),
    [orders, channel]
  );

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = validOrders.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (order, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  return (
    <>
      <List>
        <ListItem sx={{ mb: 2 }}>
          <ListItemText
            primary={`Estimated cost: ${price.toFixed(2)} Lei`}
            secondary={`Selected: ${selected.length}/${validOrders.length}`}
          />
          <ListItemSecondaryAction>
            <Checkbox
              indeterminate={selected.length > 0 && selected.length < validOrders.length}
              checked={validOrders.length > 0 && selected.length > 0 && selected.length === validOrders.length}
              onChange={handleSelectAllClick}
            />
          </ListItemSecondaryAction>
        </ListItem>
        {validOrders.map(({ id, first_name, last_name, tickets }) => (
          <ListItem key={id} divider>
            <ListItemText
              primary={`#${id} ${
                first_name || last_name ? '- ' + `${first_name || ''} ${last_name || ''}`.trim() : ''
              }`}
              secondary={tickets
                .map(({ seatsio_object_id, section, parent, own }) =>
                  seatIdToSectionParentOwn(seatsio_object_id, section, parent, own)
                )
                .join(' - ')}
            />
            <ListItemSecondaryAction>
              <Checkbox checked={isSelected(id)} onClick={(event) => handleClick(event, id)} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default function NotificationsDialog({ event, open, onClose }) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.notifications.isLoading);
  const orders = useSelector((state) => {
    const orders = state.events.selectedEvent.orders;
    let data = {};
    for (const {
      id,
      seatsio_object_id,
      created_at,
      section,
      parent,
      own,
      first_name,
      last_name,
      email,
      phone,
    } of orders) {
      if (!data[id]) {
        data[id] = {
          id,
          created_at,
          tickets: [{ seatsio_object_id, section, parent, own }],
          first_name,
          last_name,
          email,
          phone,
        };
      } else {
        data[id].tickets.push({ seatsio_object_id, section, parent, own });
      }
    }
    return Object.values(data);
  });

  const pricingTable = useSelector((state) => ({
    [ChannelType.SMS]: Number(state.events.selectedEvent.notification_sms_price),
    [ChannelType.EMAIL]: Number(state.events.selectedEvent.notification_email_price),
  }));

  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openWarning, setOpenWarning] = useState(false);

  const [channel, setChannel] = useState(ChannelType.EMAIL);
  const [body, setBody] = useState('');
  const [subject, setSubject] = useState('');
  const [selected, setSelected] = useState([]);
  const [step, setStep] = useState(NotificationStep.FIRST);
  const [includeAttachments, setIncludeAttachments] = useState(false);

  const nextDisabled = useMemo(() => {
    if (channel === ChannelType.SMS && step === NotificationStep.FIRST && (!body || body.length > 160)) return true;
    if (channel === ChannelType.EMAIL && step === NotificationStep.FIRST && (!subject || !body)) return true;

    if (step === NotificationStep.SECOND && !selected.length) return true;
  }, [selected, step, channel, subject, body]);

  const resetState = useCallback(() => {
    setStep(NotificationStep.FIRST);
    setChannel(ChannelType.EMAIL);
    setSelected([]);
    setIncludeAttachments(false);
    setSubject('');
    setBody('');
  }, [setStep, setChannel, setSubject, setBody]);

  const toggleOpenWarning = useCallback(() => {
    setOpenWarning(!openWarning);
  }, [openWarning, setOpenWarning]);

  const onFirstAction = useCallback(() => {
    if (step === NotificationStep.FIRST) {
      onClose();
      resetState();
    } else if (step === NotificationStep.SECOND) {
      setStep(NotificationStep.FIRST);
    }
  }, [step, setStep, onClose, resetState]);

  const onSecondAction = useCallback(() => {
    if (step === NotificationStep.FIRST) {
      setStep(NotificationStep.SECOND);
    } else if (step === NotificationStep.SECOND) {
      toggleOpenWarning();
    }
  }, [step, setStep, toggleOpenWarning]);

  const onConfirmInternal = () => {
    const payload = {
      event_id: event.id,
      order_ids: selected,
      channel: channel,
      subject,
      body,
      include_attachments: includeAttachments,
    };

    dispatch(createNotifications(payload))
      .unwrap()
      .then(() => {
        resetState();
        dispatch(fetchNotifications({ eventId: event.id }));
        toast.success('Notifications scheduled successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        toggleOpenWarning();
        onClose();
      })
      .catch(() => {
        toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
      });
  };

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={open}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <NotificationsIcon sx={{ marginRight: 1 }} />
            Notifications
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {step === NotificationStep.FIRST ? (
            <FirstStep
              {...{
                includeAttachments,
                setIncludeAttachments,
                channel,
                setChannel,
                body,
                setBody,
                subject,
                setSubject,
              }}
            />
          ) : (
            <SecondStep {...{ selected, setSelected, orders, channel, pricingTable }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="outlined" onClick={onFirstAction}>
            {step === NotificationStep.FIRST ? 'Cancel' : 'Back'}
          </Button>
          <Button disabled={nextDisabled} onClick={onSecondAction} variant="contained">
            {step === NotificationStep.FIRST ? 'Next' : 'Send'}
          </Button>
        </DialogActions>
      </Dialog>
      <WarningDialog
        title={`Warning`}
        content={'Are you sure you want to send notifications?'}
        loading={loading}
        open={openWarning}
        onCancel={toggleOpenWarning}
        onConfirm={onConfirmInternal}
      />
    </>
  );
}
