import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import validator from 'validator';
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCharts } from '../redux/slices/chartsSlice';
import { fetchPlays } from '../redux/slices/playsSlice';
import { fetchOrganizerCompanies } from '../redux/slices/organizersSlice';
import { createEvent, deleteEvent, updateEvent } from '../redux/slices/eventsSlice';
import { PromotionType } from '../redux/slices/cartSlice';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import FormGroup from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import WarningDialog from './WarningDialog';
import api from '../api';

export const EventsDrawerType = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  COPY: 'COPY',
};

function EventsDrawer({ open, type = EventsDrawerType.CREATE, event, onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedChart, setSelectedChart] = useState('');
  const [selectedPlay, setSelectedPlay] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [onePlusOne, setOnePlusOne] = useState(false);
  const [index, setIndex] = useState(true);
  const [threePlusOne, setThreePlusOne] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [price, setPrice] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [tickets, setTickets] = useState([]);
  const [warningOpen, setWarningOpen] = useState(false);
  const [premiere, setPremiere] = useState(false);
  const [nationalPremiere, setNationalPremiere] = useState(false);
  const [secondRepresentation, setSecondRepresentation] = useState(false);
  const [categoryTypes, setCategoryTypes] = useState([]);

  const fetchCategoryTypes = () => {
    api
      .get(`/categoryPricings/types`)
      .then((res) => {
        setCategoryTypes(res.data.data);
      })
      .catch(() => {
        toast.error('An error occured, please refresh the page and try again!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setCategoryTypes([]);
      });
  };

  const chartsData = useSelector(({ charts: { data } }) =>
    data.map(({ id, name, city, venue_name, categories }) => ({
      id,
      name: `${city} - ${venue_name} - ${name}`,
      categories,
    }))
  );
  const chartsDataLoading = useSelector(({ charts: { isLoading } }) => isLoading);

  const selectedChartCategories = useMemo(() => {
    const venue = chartsData.find(({ id }) => id === selectedChart);
    return venue
      ? venue.categories.map((c) => ({
          ...c,
          disabled: tickets.filter((t) => t.categoryId === c.id).length === categoryTypes.length,
        }))
      : [];
  }, [chartsData, selectedChart, tickets, categoryTypes]);

  const selectedCategoryTypes = useMemo(() => {
    return categoryTypes.map(({ id, label, default: is_default }) => ({
      id,
      label,
      is_default,
      disabled: !!tickets.find((t) => t.categoryId === selectedCategory && t.type_id === id),
    }));
  }, [selectedCategory, tickets, categoryTypes]);

  const chartsDataError = useSelector(({ charts: { error } }) => error);

  const playsData = useSelector(({ plays: { data } }) => data.map(({ id, name }) => ({ id, name })));
  const playsDataLoading = useSelector(({ plays: { isLoading } }) => isLoading);
  const playsDataError = useSelector(({ plays: { error } }) => error);

  const companiesData = useSelector((state) =>
    state.organizers.organizerCompanies.map(({ id, name, is_default }) => ({ id, name, is_default }))
  );
  const companiesDataLoading = useSelector((state) => state.organizers.isLoading);
  const companiesDataError = useSelector((state) => state.organizers.error);

  const isLoading = useSelector((state) => state.events.isLoading);

  const createDisabled = useMemo(
    () =>
      isLoading ||
      !selectedChart ||
      !selectedPlay ||
      !selectedDate ||
      dayjs(selectedDate).isBefore(dayjs()) ||
      !selectedCompany,
    [isLoading, selectedChart, selectedPlay, selectedDate, selectedCompany]
  );

  const updateDisabled = useMemo(
    () => isLoading || !selectedChart || !selectedPlay || !selectedDate || dayjs(selectedDate).isBefore(dayjs()),
    [isLoading, selectedChart, selectedPlay, selectedDate]
  );

  const toggleOpenWarning = () => {
    setWarningOpen(!warningOpen);
  };

  const onTogglePromotion = (type) => {
    if (type === PromotionType.ONE_PLUS_ONE) {
      return (event) => {
        const checked = event.target.checked;
        if (checked) {
          setThreePlusOne(false);
        }

        setOnePlusOne(checked);
      };
    }

    if (type === PromotionType.THREE_PLUS_ONE) {
      return (event) => {
        const checked = event.target.checked;
        if (checked) {
          setOnePlusOne(false);
        }

        setThreePlusOne(checked);
      };
    }
  };

  useEffect(() => {
    if (open) {
      fetchCategoryTypes();
      dispatch(fetchCharts());
      dispatch(fetchPlays({ status: 'ACTIVE' }));
      dispatch(fetchOrganizerCompanies());
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (playsDataError || chartsDataError || companiesDataError) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [playsDataError, chartsDataError, companiesDataError]);

  useEffect(() => {
    if (event) {
      const {
        charts_id,
        plays_id,
        date,
        status,
        premiere,
        companies_id,
        is_one_plus_one_free,
        is_three_plus_one_free,
        national_premiere,
        second_representation,
        index_for_search,
      } = event;
      setSelectedCompany(companies_id);
      setSelectedChart(charts_id);
      setSelectedPlay(plays_id);
      setSelectedDate(dayjs(date));
      setSelectedStatus(status);
      setIndex(Boolean(index_for_search));
      setPremiere(Boolean(premiere));
      setNationalPremiere(Boolean(national_premiere));
      setSecondRepresentation(Boolean(second_representation));
      setOnePlusOne(Boolean(is_one_plus_one_free));
      setThreePlusOne(Boolean(is_three_plus_one_free));

      setTickets(
        event.tickets.map(
          ({ id, type_id, type_label, is_default_type, color, price, discount, name, categories_id: categoryId }) => ({
            id,
            type_id,
            type_label,
            is_default_type,
            color,
            price,
            discount,
            name,
            categoryId,
            canRemove: type !== EventsDrawerType.UPDATE,
          })
        )
      );
    }
  }, [event, type]);

  const onDrawerClose = () => {
    setSelectedChart('');
    setSelectedPlay('');
    setSelectedDate(null);
    setSelectedCategory('');
    setSelectedType('');
    setPrice('');
    setSelectedStatus('');
    setTickets([]);
    setOnePlusOne(false);
    setSelectedCompany('');
    setIndex(true);
    setPremiere(false);
    setNationalPremiere(false);
    setSecondRepresentation(false);
    setCategoryTypes([]);
    onClose();
  };

  const addTicket = () => {
    const ids = tickets.map(({ id }) => id);
    const nextId = Math.max(...(ids.length ? ids : [-1])) + 1;
    const { id, color, name } = selectedChartCategories.find(({ id }) => id === selectedCategory);
    const { id: typeId, label, is_default } = selectedCategoryTypes.find(({ id }) => id === selectedType);
    setTickets((current) => [
      ...current,
      {
        id: nextId,
        type_id: typeId,
        type_label: label,
        is_default_type: is_default,
        categoryId: id,
        name,
        color,
        price: Number(price),
        discount: 0,
        canRemove: true,
      },
    ]);
    setSelectedCategory('');
    setSelectedType('');
    setPrice('');
  };

  const removeTicket = (idToRemove) => {
    const idx = tickets.findIndex(({ id }) => idToRemove === id);
    const newState = [...tickets];
    newState.splice(idx, 1);
    setTickets(newState);
  };

  const onUpdatePrice = (event) => {
    const value = event.target.value;
    if (validator.isDecimal(value, { decimal_digits: '0,2' }) || value === '') {
      setPrice(value);
    }
  };

  const onCreateEvent = () => {
    const payload = {
      date: selectedDate.toISOString(),
      chart_id: selectedChart,
      play_id: selectedPlay,
      company_id: selectedCompany,
      is_one_plus_one_free: onePlusOne,
      is_three_plus_one_free: threePlusOne,
      index_for_search: index,
      premiere: premiere,
      national_premiere: nationalPremiere,
      second_representation: secondRepresentation,
      category_pricings: tickets.map(({ type_id, categoryId, price }) => ({ typeId: type_id, categoryId, price })),
    };
    dispatch(createEvent(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Event created successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  const onUpdateEvent = () => {
    const payload = {
      eventId: event.id,
      status: selectedStatus,
      date: selectedDate.toISOString(),
      play_id: selectedPlay,
      index_for_search: index,
      premiere: premiere,
      national_premiere: nationalPremiere,
      second_representation: secondRepresentation,
      category_pricings: tickets
        .filter((c) => c.canRemove === true)
        .map(({ type_id, categoryId, price }) => ({ typeId: type_id, categoryId, price })),
    };
    dispatch(updateEvent(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Event updated successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  const onDeleteEvent = () => {
    const payload = {
      eventId: event.id,
    };
    dispatch(deleteEvent(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Event deleted successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        toggleOpenWarning();
        onDrawerClose();
        navigate(-1);
      }
    });
  };

  return (
    <Drawer anchor="right" open={open}>
      <Toolbar />
      <Toolbar>
        <Typography variant="button">
          {type === EventsDrawerType.CREATE
            ? 'Create Event'
            : type === EventsDrawerType.UPDATE
            ? 'Edit Event'
            : 'Copy Event'}
        </Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ width: 400, pr: 2, pl: 2 }}>
        <Box sx={{ pb: 2 }} component="form">
          {type === EventsDrawerType.CREATE && (
            <FormControl
              required
              variant="standard"
              margin="normal"
              size="small"
              fullWidth
              disabled={chartsDataLoading}
            >
              <InputLabel id="chart-label">Chart</InputLabel>
              <Select
                value={selectedChart}
                onChange={(event) => setSelectedChart(event.target.value)}
                labelId="chart-label"
                id="chart"
              >
                {chartsData.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl variant="standard" required margin="normal" size="small" fullWidth disabled={playsDataLoading}>
            <InputLabel id="play-label">Play</InputLabel>
            <Select
              value={selectedPlay}
              onChange={(event) => setSelectedPlay(event.target.value)}
              labelId="play-label"
              id="play"
            >
              {playsData.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {[EventsDrawerType.CREATE, EventsDrawerType.COPY].includes(type) && (
            <FormControl
              variant="standard"
              required
              margin="normal"
              size="small"
              fullWidth
              disabled={companiesDataLoading}
            >
              <InputLabel id="play-label">Company</InputLabel>
              <Select
                value={selectedCompany}
                onChange={(event) => setSelectedCompany(event.target.value)}
                labelId="company-label"
                id="company"
              >
                {companiesData.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <DateTimePicker
            disablePast
            value={selectedDate}
            onChange={(value) => setSelectedDate(value)}
            slotProps={{
              textField: { required: true, label: 'Date', fullWidth: true, margin: 'normal', variant: 'standard' },
            }}
          />
          {type === EventsDrawerType.UPDATE && (
            <FormControl variant="standard" margin="normal" size="small" fullWidth>
              <InputLabel id="event-status">Status</InputLabel>
              <Select
                value={selectedStatus}
                onChange={(event) => setSelectedStatus(event.target.value)}
                labelId="event-status"
                id="event-status"
              >
                <MenuItem value={'SELLING'}>Selling</MenuItem>
                <MenuItem value={'NOT_SELLING'}>Not selling</MenuItem>
                <MenuItem value={'ARCHIVED'}>Archived</MenuItem>
              </Select>
            </FormControl>
          )}
          {[EventsDrawerType.CREATE, EventsDrawerType.COPY].includes(type) && (
            <FormGroup sx={{ display: 'block', mt: 2 }}>
              <FormControlLabel
                control={<Switch checked={onePlusOne} onChange={onTogglePromotion(PromotionType.ONE_PLUS_ONE)} />}
                label="Toggle 1+1 Free"
              />
            </FormGroup>
          )}
          {[EventsDrawerType.CREATE, EventsDrawerType.COPY].includes(type) && (
            <FormGroup sx={{ display: 'block', mt: 2 }}>
              <FormControlLabel
                control={<Switch checked={threePlusOne} onChange={onTogglePromotion(PromotionType.THREE_PLUS_ONE)} />}
                label="Toggle 3+1 Free"
              />
            </FormGroup>
          )}
          <FormGroup sx={{ display: 'block', mt: 2 }}>
            <FormControlLabel
              control={<Switch checked={premiere} onChange={(event) => setPremiere(event.target.checked)} />}
              label="Premiere"
            />
          </FormGroup>
          <FormGroup sx={{ display: 'block', mt: 2 }}>
            <FormControlLabel
              control={
                <Switch checked={nationalPremiere} onChange={(event) => setNationalPremiere(event.target.checked)} />
              }
              label="National premiere"
            />
          </FormGroup>
          <FormGroup sx={{ display: 'block', mt: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={secondRepresentation}
                  onChange={(event) => setSecondRepresentation(event.target.checked)}
                />
              }
              label="Second representation"
            />
          </FormGroup>
        </Box>
        <Box component="form">
          <Typography variant="button">Category pricings</Typography>
          <FormControl
            disabled={!selectedChart || !selectedChartCategories.length || chartsDataLoading}
            variant="standard"
            margin="normal"
            size="small"
            fullWidth
          >
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={(event) => setSelectedCategory(event.target.value)}
              labelId="category-label"
              id="category"
            >
              {selectedChartCategories.map(({ id, name, disabled }) => (
                <MenuItem disabled={disabled} key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            disabled={!selectedCategory || !selectedCategoryTypes.length}
            variant="standard"
            margin="normal"
            size="small"
            fullWidth
          >
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              value={selectedType}
              onChange={(event) => setSelectedType(event.target.value)}
              labelId="type-label"
              id="type"
            >
              {selectedCategoryTypes.map(({ id, label, is_default, disabled }) => (
                <MenuItem disabled={disabled} key={id} value={id}>
                  {label}
                  {!!is_default && ' (Default)'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            disabled={!selectedCategory || !selectedType}
            value={price}
            onChange={onUpdatePrice}
            variant="standard"
            margin="normal"
            size="small"
            fullWidth
            id="price"
            label="Price"
          />
          <Button
            disabled={!selectedChart || !selectedChartCategories.length || !price || isNaN(price) || !selectedCategory}
            onClick={addTicket}
            fullWidth
            variant="text"
            size="small"
          >
            Add
          </Button>
        </Box>
        <List>
          {tickets.map(({ id, name, type_id, type_label, is_default_type, color, price, discount, canRemove }) => (
            <ListItem sx={{ borderLeft: `5px solid ${color}` }} key={`${id}-${type_id}`} divider>
              <ListItemText
                primary={
                  <>
                    {name}{' '}
                    {!is_default_type && <Chip sx={{ maxWidth: 125, marginLeft: 1 }} size="small" label={type_label} />}
                  </>
                }
              />

              <ListItemSecondaryAction>
                {(price - price * discount).toFixed(2) + ' Lei'}
                {canRemove && (
                  <IconButton onClick={() => removeTicket(id)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <FormGroup sx={{ display: 'block', mt: 2, mb: 2 }}>
          <FormControlLabel
            control={<Switch checked={index} onChange={(event) => setIndex(event.target.checked)} />}
            label="Index in main site"
          />
        </FormGroup>
        {type === EventsDrawerType.UPDATE && (
          <>
            <Button disabled={event && !event.can_delete} onClick={toggleOpenWarning} fullWidth variant="outlined">
              Delete
            </Button>
            <WarningDialog
              open={warningOpen}
              loading={isLoading}
              onCancel={toggleOpenWarning}
              onConfirm={onDeleteEvent}
              title="Warning"
              content="Are you sure you want to delete this event?"
            />
          </>
        )}
      </Box>
      <Box sx={{ p: 2, mt: 'auto' }}>
        <Grid columnSpacing={2} container>
          <Grid xs={6} item>
            <Button fullWidth variant="outlined" onClick={onDrawerClose}>
              Cancel
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              disabled={
                [EventsDrawerType.CREATE, EventsDrawerType.COPY].includes(type) ? createDisabled : updateDisabled
              }
              fullWidth
              variant="contained"
              onClick={[EventsDrawerType.CREATE, EventsDrawerType.COPY].includes(type) ? onCreateEvent : onUpdateEvent}
            >
              {type === EventsDrawerType.CREATE
                ? 'Create Event'
                : type === EventsDrawerType.UPDATE
                ? 'Update Event'
                : 'Copy Event'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}

export default EventsDrawer;
