import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  events: {
    showStats: false,
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    toggleShowStats: (state) => {
      state.events.showStats = !state.events.showStats;
    },
  },
});

export const { toggleShowStats } = uiSlice.actions;

export default uiSlice.reducer;
