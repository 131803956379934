const ClientUIPath = {
  Events: '/bilete-teatru',
};

const slug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;ăâîșşț';
  var to = 'aaaaaeeeeeiiiiooooouuuunc------aaisst';
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

const eventSlug = (event) => {
  const { id, title, city, cast } = event;

  const castStr = cast?.length
    ? cast
        ?.slice(0, 3)
        .map(({ name }) => name)
        .join('-')
    : '';

  return `${slug(city)}-${slug(title?.split?.(' //')?.[0])}-${slug(castStr)}-${id}`;
};

export const eventUrl = (event) => {
  if (!event) return '';
  console.log(`${process.env.REACT_APP_CLIENT_PUBLIC_URL}${ClientUIPath.Events}/${eventSlug(event)}`);
  return `${process.env.REACT_APP_CLIENT_PUBLIC_URL}${ClientUIPath.Events}/${eventSlug(event)}`;
};
