import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const fetchPlays = createAsyncThunk('plays/list', (args = {}) => {
  const { status } = args;
  return api.get(`/plays?status=${status ? encodeURIComponent(status) : ''}`).then((response) => response.data);
});

export const createPlay = createAsyncThunk('plays/create', (args, { dispatch }) => {
  const {
    name,
    description,
    author,
    scenography,
    director,
    music,
    lights,
    translation,
    producer,
    choreography,
    video_design,
    length,
    cast,
    portrait,
    cover,
    index_for_search,
    has_breaks,
    min_rec_age,
  } = args;
  const formData = new FormData();
  formData.append('name', name);
  formData.append('description', description);
  for (const id of cast) {
    formData.append('cast[]', id);
  }
  formData.append('portrait', portrait);
  formData.append('cover', cover);
  formData.append('author', author);
  formData.append('director', director);
  formData.append('scenography', scenography);
  formData.append('music', music);
  formData.append('lights', lights);
  formData.append('translation', translation);
  formData.append('producer', producer);
  formData.append('choreography', choreography);
  formData.append('video_design', video_design);
  formData.append('length', length);
  formData.append('index_for_search', index_for_search);
  formData.append('has_breaks', has_breaks);
  formData.append('min_rec_age', min_rec_age);
  return api.post('/plays', formData).then(() => dispatch(fetchPlays()));
});

export const updatePlay = createAsyncThunk('plays/update', (args, { dispatch }) => {
  const {
    playId,
    name,
    status,
    description,
    author,
    scenography,
    director,
    music,
    lights,
    translation,
    producer,
    choreography,
    video_design,
    length,
    cast,
    portrait,
    cover,
    index_for_search,
    has_breaks,
    min_rec_age,
  } = args;
  const formData = new FormData();
  formData.append('name', name);
  formData.append('status', status);
  formData.append('description', description);
  for (const id of cast) {
    formData.append('cast[]', id);
  }
  formData.append('portrait', portrait);
  formData.append('cover', cover);
  formData.append('index_for_search', index_for_search);
  formData.append('author', author);
  formData.append('director', director);
  formData.append('scenography', scenography);
  formData.append('music', music);
  formData.append('lights', lights);
  formData.append('translation', translation);
  formData.append('producer', producer);
  formData.append('choreography', choreography);
  formData.append('video_design', video_design);
  formData.append('length', length);
  formData.append('has_breaks', has_breaks);
  formData.append('min_rec_age', min_rec_age);
  return api.patch(`/plays/${playId}`, formData).then(() => dispatch(fetchPlays()));
});

export const deletePlay = createAsyncThunk('plays/delete', (args, { dispatch }) => {
  const { playId } = args;
  return api.delete(`/plays/${playId}`).then(() => dispatch(fetchPlays()));
});

const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

const playsSlice = createSlice({
  name: 'plays',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlays.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(fetchPlays.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });

    builder.addCase(fetchPlays.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(createPlay.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(createPlay.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createPlay.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(updatePlay.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(updatePlay.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updatePlay.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(deletePlay.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(deletePlay.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deletePlay.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default playsSlice.reducer;
