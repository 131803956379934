import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import LocationIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn';
import FlashlightOffIcon from '@mui/icons-material/FlashlightOff';
import seatInfoFormatter from '../utils/formatters';
import jwt_decode from 'jwt-decode';
import api from '../api';

const ScanResult = {
  NOT_FOUND: 'NOT_FOUND',
  SCANNED: 'SCANNED',
  NOT_SCANNED: 'NOT_SCANNED',
};

export default function Scan() {
  const { code } = useParams();

  const videoElementRef = useRef(null);
  const qrScannerRef = useRef(null);
  const [eventData, setEventData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [resultOpen, setResultOpen] = useState(false);

  const [flashOn, setFlashOn] = useState(false);
  const [hasFlash, setHasFlash] = useState(false);

  const closeResults = () => {
    qrScannerRef.current.start();
    setResult(null);
    setResultOpen(false);
  };

  const toggleFlash = () => {
    setFlashOn(!flashOn);
    qrScannerRef.current.toggleFlash();
  };

  useEffect(() => {
    try {
      const { id, date, title, city, location, organizer } = jwt_decode(code);

      if (id && date && title && city && location && organizer) {
        setEventData({ id, date, title, city, location, organizer });
      } else {
        throw new Error('Cod scanare invalid!');
      }
    } catch (err) {
      toast.error('Cod scanare invalid!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [code]);

  const scanTicket = (code, ticket_serial) => {
    setIsLoading(true);
    api
      .post(`/scan`, { code, ticket_serial })
      .then((res) => {
        setIsLoading(false);
        setResult(res.data);
      })
      .catch(() => {
        toast.error('A apărut o problema, reincercati!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setResult(null);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (eventData) {
      QrScanner.hasCamera().then((hasCamera) => {
        if (hasCamera) {
          const video = videoElementRef.current;
          qrScannerRef.current = new QrScanner(
            video,
            (result) => {
              const { data } = result;
              if (data) {
                scanTicket(code, result.data);
                setResultOpen(true);
                qrScannerRef.current.stop();
              }
            },
            {
              maxScansPerSecond: 10,
              returnDetailedScanResult: true,
              highlightScanRegion: true,
              highlightCodeOutline: true,
            }
          );

          qrScannerRef.current.hasFlash().then((flash) => {
            if (flash) {
              qrScannerRef.current.isFlashOn().then((on) => setFlashOn(on));
            }
            setHasFlash(flash);
          });

          qrScannerRef.current.setCamera('environment').then(() => {
            qrScannerRef.current.start();
          });
        }
      });
    }

    return () => {
      if (qrScannerRef.current) {
        qrScannerRef.current.stop();
        qrScannerRef.current.destroy();
      }
    };
  }, [code, eventData]);

  return (
    <div className="scan">
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Box display="flex" alignItems="center">
            <Typography sx={{ flexGrow: 1, color: 'white', textDecoration: 'none' }} variant="h6" noWrap>
              POFTIM CULTURA
            </Typography>
            <Chip sx={{ ml: 1, borderRadius: 1 }} size="small" color="primary" variant="contained" label="scan" />
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {eventData && (
        <Container maxWidth="xs">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '24px',
              marginBottom: '24px',
            }}
          >
            <video
              style={{
                objectFit: 'cover',
                width: '350px',
                height: '350px',
                borderRadius: '35px',
              }}
              ref={videoElementRef}
            />
          </div>
          <Box mr={3} ml={3} mb={2}>
            <ToggleButton disabled={!hasFlash} color="primary" value="flash" selected={flashOn} onChange={toggleFlash}>
              {flashOn ? <FlashlightOnIcon /> : <FlashlightOffIcon />}
            </ToggleButton>
          </Box>
          <Box mr={3} ml={3}>
            <Typography gutterBottom variant="h4">
              {eventData.title}
            </Typography>
            <Typography gutterBottom variant="h6">
              {eventData.organizer}
            </Typography>
            <Box display="flex" alignItems="center">
              <CalendarIcon sx={{ mr: 1 }} fontSize="small" />
              <Typography variant="subtitle1">
                {dayjs(eventData.date).format('DD/MM/YYYY')} {dayjs(eventData.date).format('HH:mm')}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationIcon sx={{ mr: 1 }} fontSize="small" />
              <Typography variant="subtitle1">{eventData.location}</Typography>
            </Box>
            <Box sx={{ mb: 3 }} display="flex" alignItems="center">
              <LocationCityIcon sx={{ mr: 1 }} fontSize="small" />
              <Typography variant="subtitle1">{eventData.city}</Typography>
            </Box>
          </Box>
        </Container>
      )}
      <Dialog
        PaperProps={{
          style: {
            backgroundImage: 'unset',
            backgroundColor: '#1E1E1E',
          },
        }}
        fullScreen
        open={resultOpen}
      >
        <DialogContent
          sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {isLoading && (
            <Typography textAlign="center" variant="h4">
              Se încarcă...
            </Typography>
          )}
          {result && !isLoading && (
            <Box flexDirection="column" display="flex" alignItems="center" justifyContent="center">
              {result.scan_result === ScanResult.NOT_SCANNED && (
                <CheckCircleIcon sx={{ fontSize: '8rem' }} color="success" />
              )}
              {result.scan_result === ScanResult.NOT_FOUND && <ErrorIcon sx={{ fontSize: '8rem' }} color="error" />}
              {result.scan_result === ScanResult.SCANNED && <WarningIcon sx={{ fontSize: '8rem' }} color="warning" />}

              {result.scan_result === ScanResult.NOT_SCANNED && (
                <Typography textAlign="center" variant="h4">
                  Bilet validat
                </Typography>
              )}
              {result.scan_result === ScanResult.SCANNED && (
                <Typography textAlign="center" variant="h4">
                  Bilet deja validat
                </Typography>
              )}
              {result.scan_result === ScanResult.NOT_FOUND && (
                <Typography textAlign="center" variant="h4">
                  Bilet negăsit sau invalid
                </Typography>
              )}
              {result.scan_result !== ScanResult.NOT_FOUND && (
                <Typography textAlign="center" variant="h6">
                  Comanda: #{result.ticket_data.orders_id}
                </Typography>
              )}
              {result.scan_result !== ScanResult.NOT_FOUND && (
                <Typography textAlign="center" variant="h6">
                  {`${result.ticket_data.category_name} - ${seatInfoFormatter(
                    result.ticket_data.section,
                    result.ticket_data.parent,
                    result.ticket_data.own
                  )}`}
                </Typography>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ mb: 3 }}>
          <Button fullWidth onClick={closeResults} variant="contained">
            Înapoi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
