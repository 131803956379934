import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const fetchEvents = createAsyncThunk('events/fetchEvents', () => {
  return api.get('/events').then(({ data: { events } }) => events);
});

export const fetchEventHeavy = createAsyncThunk('events/fetchEventHeavy', (args) => {
  const { eventId } = args;
  return api
    .get(`/events/${eventId}`)
    .then(({ data: { data } }) => {
      const { id, ...rest } = data[0];
      return Promise.all([
        api.get(`/events/${eventId}/orders`),
        api.get(`/events/${eventId}/categoryPricings?type=RAW`),
      ]).then(([orders, categories]) => {
        const {
          data: { data: ordersData },
        } = orders;

        const {
          data: { data: ticketData },
        } = categories;

        return {
          id,
          ...rest,
          orders: ordersData,
          tickets: ticketData.map(({ discount, price, ...rest }) => ({
            discount: Number(discount),
            price: Number(price),
            ...rest,
          })),
        };
      });
    })
    .then((response) => ({ data: response }));
});

export const fetchEventLight = createAsyncThunk('events/fetchEventLight', (args) => {
  // TODO: the raw stuff is a stupid hack
  const { eventId, fetchRawCategoryPricings } = args;
  return api
    .get(`/events/${eventId}`)
    .then(({ data: { data } }) => {
      const event = data[0];
      return api
        .get(`/events/${eventId}/categoryPricings${fetchRawCategoryPricings ? '?type=RAW' : ''}`)
        .then(({ data: { data: ticketData } }) => ({
          ...event,
          tickets: ticketData.map(({ discount, price, ...rest }) => ({
            discount: Number(discount),
            price: Number(price),
            ...rest,
          })),
        }));
    })
    .then((response) => ({ data: response }));
});

export const createEvent = createAsyncThunk('events/create', (args, { dispatch }) => {
  const {
    date,
    chart_id,
    play_id,
    company_id,
    category_pricings,
    is_one_plus_one_free,
    is_three_plus_one_free,
    index_for_search,
    premiere,
    national_premiere,
    second_representation,
  } = args;
  return api
    .post('/events', {
      date,
      chart_id,
      play_id,
      company_id,
      is_one_plus_one_free,
      is_three_plus_one_free,
      index_for_search,
      premiere,
      national_premiere,
      second_representation,
      category_pricings,
    })
    .then(() => dispatch(fetchEvents()));
});

export const updateEvent = createAsyncThunk('events/update', (args, { dispatch }) => {
  const {
    eventId,
    date,
    status,
    play_id,
    category_pricings,
    index_for_search,
    premiere,
    national_premiere,
    second_representation,
  } = args;
  return api
    .put(`/events/${eventId}`, {
      date,
      status,
      play_id,
      category_pricings,
      index_for_search,
      premiere,
      national_premiere,
      second_representation,
    })
    .then(() => dispatch(fetchEventHeavy({ eventId })))
    .then(() => dispatch(fetchEventFiscalSerials({ eventId })))
    .then(() => dispatch(fetchCategoryPricingStats({ eventId })));
});

export const fetchEventFiscalSerials = createAsyncThunk('events/fetchFiscalSerials', (args) => {
  const { eventId } = args;
  return api.get(`/events/${eventId}/fiscalSerials`).then(({ data }) => data);
});

export const fetchCategoryPricingStats = createAsyncThunk('events/fetchCategoryPricingStats', (args) => {
  const { eventId } = args;
  return api.get(`/events/${eventId}/categoryPricingStatistics`).then(({ data }) => data);
});

export const fetchHistory = createAsyncThunk('events/fetchHistory', (args) => {
  const { eventId } = args;
  return api.get(`/events/${eventId}/history`).then(({ data: { data } }) => data);
});

export const deleteEvent = createAsyncThunk('events/delete', (args) => {
  const { eventId } = args;
  return api.delete(`/events/${eventId}`).then((res) => res.data);
});

const initialState = {
  selectedEvent: {
    title: '-',
    location: '-',
    city: '-',
    date: '',
    scan_code: '',
    is_one_plus_one_free: false,
    is_three_plus_one_free: false,
    premiere: false,
    national_premiere: false,
    second_representation: false,
    platform_comission: 0,
    seat_price: 0,
    comission_cash_per_seat: false,
    notification_sms_price: 0,
    notifiction_email_price: 0,
    status: 'SELLING',
    can_delete: false,
    index_for_search: true,
    orders: [],
    tickets: [],
  },
  categoryPricingStats: {
    data: [],
    booked: 0,
    booked_cashier: 0,
    booked_organizer: 0,
    available: 0,
    not_for_sale: 0,
    capacity: 0,
    revenue_cashier: 0,
    revenue: 0,
  },
  isLoadingCategoryPricings: false,
  isLoadingHistory: false,
  history: [],
  fiscalSerials: [],
  allEvents: [],
  error: null,
  isLoading: false,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {
    clearSelectedEvent: (state) => {
      state.selectedEvent = initialState.selectedEvent;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteEvent.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(deleteEvent.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(deleteEvent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchEvents.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allEvents = action.payload;
    });

    builder.addCase(fetchEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(createEvent.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(createEvent.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(createEvent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateEvent.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(updateEvent.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateEvent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchEventHeavy.pending, (state) => {
      state.isLoading = true;
      state.selectedEvent = initialState.selectedEvent;
      state.error = null;
    });

    builder.addCase(fetchEventHeavy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedEvent = { ...state.selectedEvent, ...action.payload.data };
    });

    builder.addCase(fetchEventHeavy.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchEventLight.pending, (state) => {
      state.isLoading = true;
      state.selectedEvent = initialState.selectedEvent;
      state.error = null;
    });

    builder.addCase(fetchEventLight.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedEvent = { ...state.selectedEvent, ...action.payload.data };
    });

    builder.addCase(fetchEventLight.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchEventFiscalSerials.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(fetchEventFiscalSerials.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fiscalSerials = action.payload.data;
    });

    builder.addCase(fetchEventFiscalSerials.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchCategoryPricingStats.pending, (state) => {
      state.isLoadingCategoryPricings = true;
      state.categoryPricingStats = initialState.categoryPricingStats;
      state.error = null;
    });

    builder.addCase(fetchCategoryPricingStats.fulfilled, (state, action) => {
      state.isLoadingCategoryPricings = false;
      state.categoryPricingStats = action.payload;
    });

    builder.addCase(fetchCategoryPricingStats.rejected, (state, action) => {
      state.isLoadingCategoryPricings = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchHistory.pending, (state) => {
      state.isLoadingHistory = true;
      state.history = [];
      state.error = null;
    });

    builder.addCase(fetchHistory.fulfilled, (state, action) => {
      state.isLoadingHistory = false;
      state.history = action.payload;
    });

    builder.addCase(fetchHistory.rejected, (state, action) => {
      state.isLoadingHistory = false;
      state.error = action.error.message;
    });
  },
});

export const { clearSelectedEvent } = eventsSlice.actions;

export default eventsSlice.reducer;
