import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchCategoryPricingStats, fetchEventFiscalSerials } from '../redux/slices/eventsSlice';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Chip from '@mui/material/Chip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Input from '@mui/material/Input';
import NoData from './NoData';
import WarningDialog from './WarningDialog';
import api from '../api';

const CategoryPricingHeader = ({ role }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: 50 }} />
        <TableCell align="left">Category</TableCell>
        <TableCell align="right">Price</TableCell>
        <TableCell align="right">Discount</TableCell>
        {role === 'organizer' && <TableCell align="right">Online</TableCell>}
        <TableCell align="right">{role === 'organizer' ? 'Cashier' : 'Booked'}</TableCell>
        {role === 'organizer' && <TableCell align="right">Organizer</TableCell>}
        <TableCell align="right">Available</TableCell>
        {/*role === 'organizer' && <TableCell align="right">Not For Sale</TableCell>*/}
        <TableCell align="right">Capacity</TableCell>
        {role === 'organizer' && <TableCell align="right">Revenue Online</TableCell>}
        <TableCell align="right">{role === 'organizer' ? 'Revenue Cashier' : 'Revenue'}</TableCell>
        {role === 'organizer' && <TableCell align="right">Actions</TableCell>}
      </TableRow>
    </TableHead>
  );
};

const CategoryPricingFooter = ({
  role,
  booked,
  booked_organizer,
  booked_cashier,
  available,
  not_for_sale,
  capacity,
  revenue_cashier,
  revenue,
}) => {
  const cellStyle = {
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: '0.875rem',
    color: (theme) => theme.palette.text.primary,
  };
  return (
    <TableFooter>
      <TableRow sx={{ 'td, th': { borderBottom: 0 } }}>
        <TableCell sx={{ ...cellStyle, width: 50 }} />
        <TableCell colSpan={3} sx={cellStyle}>
          Total
        </TableCell>
        {role === 'organizer' && (
          <TableCell align="right" sx={cellStyle}>
            {booked}
          </TableCell>
        )}
        <TableCell align="right" sx={cellStyle}>
          {booked_cashier}
        </TableCell>
        {role === 'organizer' && (
          <TableCell align="right" sx={cellStyle}>
            {booked_organizer}
          </TableCell>
        )}
        <TableCell align="right" sx={cellStyle}>
          {available}
        </TableCell>
        {/*role === 'organizer' && (
          <TableCell align="right" sx={cellStyle}>
            {not_for_sale}
          </TableCell>
        )*/}
        <TableCell align="right" sx={cellStyle}>
          {capacity}
        </TableCell>
        {role === 'organizer' && (
          <TableCell align="right" sx={cellStyle}>
            {revenue.toFixed(2) + ' Lei'}
          </TableCell>
        )}
        <TableCell align="right" sx={cellStyle}>
          {revenue_cashier.toFixed(2) + ' Lei'}
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

function Row(props) {
  const { row, role, eventId, history } = props;
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDiscountError, setIsDiscountError] = useState(false);
  const [variablePrice, setVariablePrice] = useState(row.price);
  const [warningOpen, setWarningOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const originalDiscount = useMemo(() => row.discount * 100, [row.discount]);
  const [variableDiscount, setVariableDiscount] = useState(originalDiscount);

  const dispatch = useDispatch();

  const isUpdateDisabled = useMemo(
    () =>
      isError ||
      isDiscountError ||
      (parseFloat(variablePrice) === parseFloat(row.price) && variableDiscount === originalDiscount),
    [isError, isDiscountError, variablePrice, row.price, variableDiscount, originalDiscount]
  );

  const toggleOpenWarning = () => {
    setWarningOpen(!warningOpen);
  };

  const updatePrice = () => {
    setIsLoading(true);
    api
      .put(`/categoryPricings/${row.id}`, { price: variablePrice, discount: variableDiscount })
      .then(() => {
        setIsEditing(false);
        setIsLoading(false);
        toggleOpenWarning();
        dispatch(fetchCategoryPricingStats({ eventId }));
        dispatch(fetchEventFiscalSerials({ eventId }));
        toast.success('Price updated successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
      })
      .catch(() => {
        setIsLoading(false);
        setVariablePrice(row.price);
        setVariableDiscount(originalDiscount);
        toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
      });
  };

  const onPriceChange = (event) => {
    setIsError(false);
    const number = event.target.value;
    setVariablePrice(number);
    if (!/^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/.test(number)) {
      setIsError(true);
    }
  };

  const onDiscountChange = (event) => {
    setIsDiscountError(false);
    const number = event.target.value;
    setVariableDiscount(number);
    if (number === '' || !(Number.isInteger(+number) && number >= 0 && number <= 100)) {
      setIsDiscountError(true);
    }
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onCancel = () => {
    setIsEditing(false);
    setVariablePrice(row.price);
    setVariableDiscount(originalDiscount);
    setIsDiscountError(false);
    setIsError(false);
  };

  return (
    <>
      <TableRow
        sx={{
          '& > td': { whiteSpace: 'nowrap', borderBottom: 'unset' },
          '&:nth-of-type(4n+1)': (theme) => ({
            backgroundColor: theme.palette.action.hover,
          }),
        }}
      >
        <TableCell sx={{ borderLeft: `5px solid ${row.color}` }}>
          <IconButton disabled={!history.length} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ display: 'inline-flex' }}>
          {row.name}
          {!row.is_default_type && <Chip sx={{ marginLeft: 1 }} size="small" label={row.type_label} />}
        </TableCell>
        <TableCell align="right">
          <Input
            value={variablePrice}
            onChange={onPriceChange}
            disabled={!isEditing}
            error={isError}
            type="text"
            sx={{
              '&.Mui-error': { border: '1px solid red', backgroundColor: 'rgb(107 109 109)' },
              '&.Mui-disabled': {
                border: 'unset',
                backgroundColor: 'unset',
                color: 'white',
              },
              border: '1px solid rgb(107 109 109)',
              backgroundColor: 'rgb(107 109 109)',
              borderRadius: '5px',
              paddingRight: 1,
              paddingLeft: 1,
            }}
            inputProps={{
              style: {
                WebkitTextFillColor: 'white',
                fontSize: '0.875rem',
                width: '60px',
                textAlign: 'right',
              },
            }}
            disableUnderline
            endAdornment={<span style={{ fontSize: '0.875rem', marginLeft: '4px' }}>Lei</span>}
          />
        </TableCell>
        <TableCell align="right">
          <Input
            value={variableDiscount}
            onChange={onDiscountChange}
            disabled={!isEditing}
            error={isDiscountError}
            type="text"
            sx={{
              '&.Mui-error': { border: '1px solid red', backgroundColor: 'rgb(107 109 109)' },
              '&.Mui-disabled': {
                border: 'unset',
                backgroundColor: 'unset',
                color: 'white',
              },
              border: '1px solid rgb(107 109 109)',
              backgroundColor: 'rgb(107 109 109)',
              borderRadius: '5px',
              paddingRight: 1,
              paddingLeft: 1,
            }}
            inputProps={{
              style: {
                WebkitTextFillColor: 'white',
                fontSize: '0.875rem',
                width: '60px',
                textAlign: 'right',
              },
            }}
            disableUnderline
            endAdornment={<span style={{ fontSize: '0.875rem', marginLeft: '4px' }}>%</span>}
          />
        </TableCell>
        {role === 'organizer' && <TableCell align="right">{row.booked}</TableCell>}
        <TableCell align="right">{row.booked_cashier}</TableCell>
        {role === 'organizer' && <TableCell align="right">{row.booked_organizer}</TableCell>}
        <TableCell align="right">{row.available}</TableCell>
        {/*role === 'organizer' && <TableCell align="right">{row.not_for_sale}</TableCell>*/}
        <TableCell align="right">{row.capacity}</TableCell>
        {role === 'organizer' && <TableCell align="right">{row.revenue.toFixed(2) + ' Lei'}</TableCell>}
        <TableCell align="right">{row.revenue_cashier.toFixed(2) + ' Lei'}</TableCell>
        {role === 'organizer' && (
          <TableCell align="right">
            {!isEditing ? (
              <IconButton onClick={onEdit} size="small">
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <IconButton onClick={onCancel} size="small">
                  <CancelIcon />
                </IconButton>
                <IconButton disabled={isUpdateDisabled} onClick={toggleOpenWarning} size="small">
                  <CheckCircleIcon />
                </IconButton>
              </>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow sx={{ '&:last-child > td, &:last-child > th': { border: 0 } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ ml: 8, mr: 8, mb: 4, mt: 2 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Price</TableCell>
                    <TableCell align="right">Discount</TableCell>
                    {role === 'organizer' && <TableCell align="right">Booked</TableCell>}
                    <TableCell align="right">{role === 'organizer' ? 'Cashier' : 'Booked'}</TableCell>
                    {role === 'organizer' && <TableCell align="right">Invites</TableCell>}
                    <TableCell align="right">{role === 'organizer' ? 'Revenue Cashier' : 'Revenue'}</TableCell>
                    {role === 'organizer' && <TableCell align="right">Revenue</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.map((category) => (
                    <TableRow sx={{ '& > td': { whiteSpace: 'nowrap' } }} key={category.id}>
                      <TableCell align="left">{category.price + ' Lei'}</TableCell>
                      <TableCell align="right">{category.discount * 100 + ' %'}</TableCell>
                      {role === 'organizer' && <TableCell align="right">{category.booked}</TableCell>}
                      <TableCell align="right">{category.booked_cashier}</TableCell>
                      {role === 'organizer' && <TableCell align="right">{category.booked_organizer}</TableCell>}
                      <TableCell align="right">{category.revenue_cashier.toFixed(2) + ' Lei'}</TableCell>
                      {role === 'organizer' && (
                        <TableCell align="right">{category.revenue.toFixed(2) + ' Lei'}</TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <WarningDialog
        title={`Warning`}
        content={'Are you sure you want to update pricing?'}
        loading={isLoading}
        open={warningOpen}
        onCancel={toggleOpenWarning}
        onConfirm={updatePrice}
      />
    </>
  );
}

export default function CategoryPricingStats({ eventId, role, stats }) {
  const { data } = stats;
  const currentPrices = useMemo(() => data.filter(({ status }) => status === 'ACTIVE'), [data]);
  const historicalPricesById = useMemo(
    () =>
      data
        .filter(({ status }) => status === 'ARCHIVED')
        .reduce(
          (acc, value) => ({
            ...acc,
            [`${value.categories_id}-${value.type_id}`]: [
              ...(acc[`${value.categories_id}-${value.type_id}`] || []),
              value,
            ],
          }),
          {}
        ),
    [data]
  );
  return (
    <>
      <Typography gutterBottom variant="h5">
        Category Pricings
      </Typography>
      {data.length ? (
        <TableContainer sx={{ mt: 2 }} component={Paper}>
          <Table>
            <CategoryPricingHeader role={role} />
            <TableBody>
              {currentPrices.map((stat) => (
                <Row
                  key={stat.id}
                  role={role}
                  row={stat}
                  history={historicalPricesById[`${stat.categories_id}-${stat.type_id}`] || []}
                  eventId={eventId}
                />
              ))}
            </TableBody>
            <CategoryPricingFooter role={role} {...stats} />
          </Table>
        </TableContainer>
      ) : (
        <NoData />
      )}
    </>
  );
}
