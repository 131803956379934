import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import chartsReducer from './slices/chartsSlice';
import citiesReducer from './slices/citiesSlice';
import venuesSlice from './slices/venuesSlice';
import playsSlice from './slices/playsSlice';
import eventsSlice from './slices/eventsSlice';
import cartSlice from './slices/cartSlice';
import ordersSlice from './slices/ordersSlice';
import authSlice from './slices/authSlice';
import notificationsSlice from './slices/notificationsSlice';
import usersSlice from './slices/usersSlice';
import paymentPointsSlice from './slices/paymentPointsSlice';
import organizersSlice from './slices/organizersSlice';
import artistsSlice from './slices/artistsSlice';
import uiSlice from './slices/uiSlice';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel1,
  whitelist: ['auth', 'ui'],
};

const rootReducer = (state, action) => {
  if (action.type === 'auth/removeToken') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const combinedReducer = combineReducers({
  ui: uiSlice,
  charts: chartsReducer,
  cities: citiesReducer,
  venues: venuesSlice,
  plays: playsSlice,
  events: eventsSlice,
  cart: cartSlice,
  orders: ordersSlice,
  auth: authSlice,
  notifications: notificationsSlice,
  users: usersSlice,
  paymentPoints: paymentPointsSlice,
  organizers: organizersSlice,
  artists: artistsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
