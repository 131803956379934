import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { SeatsioEventManager } from '@seatsio/seatsio-react';

function ForSaleConfigDialog({ open, onClose, event }) {
  const onCloseInternal = () => {
    onClose();
  };

  return (
    <Dialog fullScreen={true} open={open} onClose={onClose}>
      <DialogTitle gutterBottom>
        For Sale Config
        <IconButton
          aria-label="close"
          onClick={onCloseInternal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 0 }}>
        <div style={{ height: '100%' }}>
          <SeatsioEventManager
            secretKey={process.env.REACT_APP_SEATSIO_PRIVATE_WORKSPACE_KEY}
            event={event.seatsio_event_id}
            mode="manageForSaleConfig"
            viewSettingsDefaults={{ showSeatLabels: true, showRowLabels: true }}
            region={process.env.REACT_APP_SEATSIO_REGION}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ForSaleConfigDialog;
