import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const fetchOrganizers = createAsyncThunk('organizers/list', () => {
  return api.get('/organizers').then((response) => response.data);
});

export const fetchOrganizer = createAsyncThunk('organizers/fetchOrganizer', (args) => {
  const { organizerId } = args;
  return api.get(`/organizers/${organizerId}`).then((res) => res.data);
});

export const fetchOrganizerUsers = createAsyncThunk('organizers/listUsers', (args) => {
  const { organizerId } = args;
  return api.get(`/organizers/${organizerId}/users`).then((response) => response.data);
});

export const fetchOrganizerVenues = createAsyncThunk('organizers/listVenues', (args) => {
  const { organizerId } = args;
  return api.get(`/organizers/${organizerId}/venues`).then((response) => response.data);
});

export const fetchOrganizerPaymentPoints = createAsyncThunk('organizers/listPaymentPoints', (args) => {
  const { organizerId } = args;
  return api.get(`/organizers/${organizerId}/paymentPoints`).then((response) => response.data);
});

export const createOrganizer = createAsyncThunk('organizers/create', (args, { dispatch }) => {
  const { name, organizer_pricing, company } = args;
  return api.post('/organizers', { name, organizer_pricing, company }).then(() => dispatch(fetchOrganizers()));
});

export const updateOrganizer = createAsyncThunk('organizer/update', (args, { dispatch }) => {
  const { organizerId, name, status, organizer_pricing, company } = args;
  return api
    .put(`/organizers/${organizerId}`, { name, status, organizer_pricing, company })
    .then(() => dispatch(fetchOrganizer({ organizerId })));
});

export const fetchOrganizerCompanies = createAsyncThunk('organizers/listCompanies', () => {
  return api.get(`/organizers/companies`).then((response) => response.data);
});

const initialState = {
  data: [],
  error: null,
  isLoading: false,

  organizerUsers: [],
  organizerVenues: [],
  organizerPaymentPoints: [],
  organizerCompanies: [],

  selectedOrganizer: {
    name: '',
    status: '',
    platform_comission: 0,
    seat_price: 0,
    comission_cash_per_seat: false,
    notification_sms_price: 0,
    notification_email_price: 0,
    companies: [],
  },
};

const organizersSlice = createSlice({
  name: 'organizers',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizers.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(fetchOrganizers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });

    builder.addCase(fetchOrganizers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchOrganizer.pending, (state) => {
      state.isLoading = true;
      state.selectedOrganizer = initialState.selectedOrganizer;
      state.error = null;
    });

    builder.addCase(fetchOrganizer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedOrganizer = action.payload.data[0];
    });

    builder.addCase(fetchOrganizer.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(createOrganizer.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(createOrganizer.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createOrganizer.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchOrganizerVenues.pending, (state, action) => {
      state.organizerVenues = [];
      state.error = null;
    });

    builder.addCase(fetchOrganizerVenues.fulfilled, (state, action) => {
      state.organizerVenues = action.payload.data;
    });

    builder.addCase(fetchOrganizerVenues.rejected, (state, action) => {
      state.error = action.error.message;
    });

    builder.addCase(fetchOrganizerUsers.pending, (state, action) => {
      state.organizerUsers = [];
      state.error = null;
    });

    builder.addCase(fetchOrganizerUsers.fulfilled, (state, action) => {
      state.organizerUsers = action.payload.data;
    });

    builder.addCase(fetchOrganizerUsers.rejected, (state, action) => {
      state.error = action.error.message;
    });

    builder.addCase(fetchOrganizerPaymentPoints.pending, (state, action) => {
      state.organizerPaymentPoints = [];
      state.error = null;
    });

    builder.addCase(fetchOrganizerPaymentPoints.fulfilled, (state, action) => {
      state.organizerPaymentPoints = action.payload.data;
    });

    builder.addCase(fetchOrganizerPaymentPoints.rejected, (state, action) => {
      state.error = action.error.message;
    });

    builder.addCase(updateOrganizer.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(updateOrganizer.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateOrganizer.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchOrganizerCompanies.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(fetchOrganizerCompanies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.organizerCompanies = action.payload.data;
    });

    builder.addCase(fetchOrganizerCompanies.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default organizersSlice.reducer;
